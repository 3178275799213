import { FuseNavigationItem } from '@fuse/components/navigation';

export default function SalesAdvisorNav() {

    const nav: FuseNavigationItem[] = [
        {
            id:'accounting',
            title: 'Contabilidad',
            subtitle: 'Administra todos los ejercicios contables',
            type: 'group',
            icon: 'heroicons_outline:banknotes',
            children:
            [
                {
                    id: 'accounting.pos',
                    title: 'POS',
                    type: 'basic',
                    icon: 'heroicons_outline:building-storefront',
                    link: '/accounting/pos'
                },
                {
                    id: 'accounting.quotations',
                    title: 'Cotizaciones',
                    type: 'basic',
                    icon: 'heroicons_outline:document-minus',
                    link: '/accounting/quotations'
                },
                {
                    id: 'accounting.invoices',
                    title: 'Facturas',
                    type: 'basic',
                    icon: 'heroicons_outline:document-text',
                    link: '/accounting/invoices'
                },
                {
                    id: 'accounting.dailyClose',
                    title: 'Cierres Diarios',
                    type: 'basic',
                    icon: 'heroicons_outline:banknotes',
                    link: '/accounting/daily-closes'
                },
            ]
        },
        {
            id: 'crm',
            title: 'CRM',
            subtitle: 'Interactua con los clientes',
            type: 'group',
            icon: 'heroicons_outline:home',
            children: [
                {
                    id: 'dashboard',
                    title: 'Dashboard',
                    type: 'basic',
                    icon: 'heroicons_outline:chart-pie',
                    link: '/dashboard/advisor'
                },
                {
                    id: 'crm.clientes',
                    title: 'Clientes',
                    type: 'basic',
                    icon: 'heroicons_outline:users',
                    link: '/crm/customers'
                },
                {
                    id: 'usersProspects',
                    title: 'Leads',
                    type: 'basic',
                    icon: 'heroicons_outline:user',
                    link: '/crm/prospects'
                },
                {
                    id: 'crm.opportunity',
                    title: 'Oportunidades',
                    type: 'basic',
                    icon: 'heroicons_outline:shopping-cart',
                    link: '/crm/opportunity'
                },
                {
                    id: 'activities',
                    title: 'Calendario de Actividades',
                    type: 'basic',
                    icon: 'heroicons_outline:calendar',
                    link: '/crm/activities'
                },
            ],
        },
        {
            id: 'inventory',
            title: 'Inventario',
            subtitle: 'Administra los productos y proveedores',
            type: 'group',
            icon: 'heroicons_outline:home',
            children: [
                {
                    id: 'inventory.products',
                    title: 'Productos',
                    type: 'basic',
                    icon: 'heroicons_outline:shopping-bag',
                    link: '/inventory/products'
                },
            ]
        }
    ];

    return nav;
}